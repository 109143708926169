<template>
  <b-sidebar
    id="addon-details-sidebar"
    ref="sidebar"
    width="50%"
    bg-variant="white"
    right
    backdrop
    no-header
    @hidden="resetData()"
    @shown="getAddonData()"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ isObjectEmpty(addonInfo) ? 'Edit Addon': 'Add Addon' }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- form -->
      <validation-observer ref="addon-form">
        <b-form
          class="p-1"
          @submit.prevent
        >
          <b-row>
            <!-- Name -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-group
                  label="Name"
                  label-for="Name"
                >
                  <b-form-input
                    id="Name"
                    v-model="addonDetail.name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Max Capacity -->
            <b-col md="6">
              <b-form-group
                label="Max Capacity"
                label-for="Max Capacity"
              >
                <b-form-input
                  id="Max Capacity"
                  v-model="addonDetail.max_capacity"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <!-- Unit -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="unit"
                rules="required"
              >
                <b-form-group
                  label="Unit"
                  label-for="Unit"
                >
                  <b-form-input
                    id="Unit"
                    v-model="addonDetail.unit"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Price -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="price"
                rules="required"
              >
                <b-form-group
                  label="Price"
                  label-for="Price"
                >
                  <b-input-group
                    prepend="BDT"
                  >
                    <b-form-input
                      id="Price"
                      v-model="addonDetail.price"
                      type="number"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Submit -->
            <b-col cols="12">
              <b-button
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="isBtnLoading"
                @click="validateForm"
              >
                <b-spinner
                  v-if="isBtnLoading"
                  small
                />
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import api from '@/libs/api'
import helper from '@/mixins/helper'

export default {
  name: 'AddonForm',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [helper],
  props: {
    addonInfo: {
      type: Object,
    },
  },
  data() {
    return {
      isBtnLoading: false,
      addonDetail: {},
      required,
    }
  },
  methods: {
    createOrUpdateAddon() {
      this.isBtnLoading = true
      let url = 'tour/admin_services/addons_create/'
      let apiResponse
      const addonExists = this.addonDetail.id
      if (addonExists) {
        url = `tour/admin_services/addons_update/${this.addonInfo.id}/`
        apiResponse = api.updateData(url, true, this.addonDetail)
      } else {
        apiResponse = api.postData(url, true, this.addonDetail)
      }
      apiResponse.then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', `addon ${addonExists ? 'updated' : 'created'} successfully`)
          this.$emit('getAddonDetails')
        } else {
          this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isBtnLoading = false
      })
    },
    resetData() {
      this.$emit('resetData')
    },
    getAddonData() {
      this.addonDetail = JSON.parse(JSON.stringify(this.addonInfo))
    },
    validateForm() {
      this.$refs['addon-form'].validate().then(success => {
        if (success) {
          this.createOrUpdateAddon()
        } else {
          this.notificationMessage('warning', 'XIcon', 'Error', 'Please fill up all required fields')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
