var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{ref:"sidebar",attrs:{"id":"addon-details-sidebar","width":"50%","bg-variant":"white","right":"","backdrop":"","no-header":""},on:{"hidden":function($event){return _vm.resetData()},"shown":function($event){return _vm.getAddonData()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.isObjectEmpty(_vm.addonInfo) ? 'Edit Addon': 'Add Addon')+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"addon-form"},[_c('b-form',{staticClass:"p-1",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name","label-for":"Name"}},[_c('b-form-input',{attrs:{"id":"Name"},model:{value:(_vm.addonDetail.name),callback:function ($$v) {_vm.$set(_vm.addonDetail, "name", $$v)},expression:"addonDetail.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Max Capacity","label-for":"Max Capacity"}},[_c('b-form-input',{attrs:{"id":"Max Capacity","type":"number"},model:{value:(_vm.addonDetail.max_capacity),callback:function ($$v) {_vm.$set(_vm.addonDetail, "max_capacity", $$v)},expression:"addonDetail.max_capacity"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Unit","label-for":"Unit"}},[_c('b-form-input',{attrs:{"id":"Unit","type":"number"},model:{value:(_vm.addonDetail.unit),callback:function ($$v) {_vm.$set(_vm.addonDetail, "unit", $$v)},expression:"addonDetail.unit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Price","label-for":"Price"}},[_c('b-input-group',{attrs:{"prepend":"BDT"}},[_c('b-form-input',{attrs:{"id":"Price","type":"number"},model:{value:(_vm.addonDetail.price),callback:function ($$v) {_vm.$set(_vm.addonDetail, "price", $$v)},expression:"addonDetail.price"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":_vm.isBtnLoading},on:{"click":_vm.validateForm}},[(_vm.isBtnLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Submit ")],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }